
import { Options } from "vue-class-component";
import NodesConfigMixins from "../NodesConfigMixins";
@Options({
    name: "flowable-nodes-condition-group-item-config",
})
export default class ConditionGroupItemConfig extends NodesConfigMixins {
    private orgList: any = [];
    private orgTypeList: any = [];
    private deptList: any = [];
    private postList: any = [];
    private userList: any = [];
    private deviceOrgList: any = [];
    private deviceOrgTypeList: any = [];
    private selectOrgTypeList: any = [];
    //private groupConditions: any = [];
    private supportTypes: any = [
        this.ValueType.number,
        this.ValueType.string,
        this.ValueType.date,
        this.ValueType.dept,
        this.ValueType.user,
        this.ValueType.org,
        this.ValueType.orgType,
        this.ValueType.deviceOrg,
        this.ValueType.deviceOrgType,
        this.ValueType.selectOrgType,
    ];
    private explains = [
        { label: "等于", value: "=" },
        { label: "不等于", value: "!=" },
        { label: "大于", value: ">" },
        { label: "大于等于", value: ">=" },
        { label: "小于", value: "<" },
        { label: "小于等于", value: "<=" },
        { label: "包含在", value: "IN" },
        { label: "x < 值 < x", value: "B" },
        { label: "x ≤ 值 < x", value: "AB" },
        { label: "x < 值 ≤ x", value: "BA" },
        { label: "x ≤ 值 ≤ x", value: "ABA" },
    ];

    get select() {
        return this.selectedNode.props.assignedUser || [];
    }
    set select(val: any) {
        this.selectedNode.props.assignedUser = val;
    }
    get formMap() {
        const map = new Map();
        this.formItems.forEach((item: any) => this.itemToMap(map, item));
        return map;
    }
    get conditionList() {
        //构造可用条件选项
        const conditionItems: any = [];
        this.formItems.forEach((item: any) => this.filterCondition(item, conditionItems));
        if (conditionItems.length === 0 || conditionItems[0].id !== "root") {
            conditionItems.unshift({ id: "deviceOrgType", title: "设备组织类型", valueType: "DeviceOrgType" });
            conditionItems.unshift({ id: "deviceOrg", title: "设备所属组织", valueType: "DeviceOrg" });
            conditionItems.unshift({ id: "selectOrgType", title: "所选组织类型", valueType: "SelectOrgType" });
            conditionItems.unshift({ id: "sendOrgType", title: "发起人组织类型", valueType: "SendOrgType" });
            conditionItems.unshift({ id: "sendOrg", title: "发起人组织", valueType: "SendOrg" });
            conditionItems.unshift({ id: "sendDept", title: "发起人部门", valueType: "SendDept" });
            conditionItems.unshift({ id: "sendPost", title: "发起人岗位", valueType: "SendPost" });
            conditionItems.unshift({ id: "sendUser", title: "发起人", valueType: "SendUser" });
        }
        return conditionItems;
    }
    public itemToMap(map: any, item: any) {
        map.set(item.id, item);
        if (item.name === "SpanLayout") {
            item.props.items.forEach((sub: any) => this.itemToMap(map, sub));
        }
    }
    public isSelect(formId: any) {
        let form = this.formMap.get(formId);
        return form && (form.name === "SelectInput" || form.name === "MultipleSelect");
    }
    public getOptions(formId: any) {
        return this.formMap.get(formId).props.options || [];
    }
    public conditionValType(type: any) {
        switch (type) {
            case "=":
            case "!=":
            case ">":
            case ">=":
            case "<":
            case "<=":
                return 0;
            case "IN":
                return 1;
            default:
                return 2;
        }
    }
    public orgPickerOpen(value: boolean) {
        this.orgList = value;
        (this.$refs.orgPicker as any).modalOpen();
    }
    public orgTypePickerOpen(value: boolean) {
        this.orgTypeList = value;
        (this.$refs.orgTypePicker as any).modalOpen();
    }
    public deptPickerOpen(value: boolean) {
        this.deptList = value;
        (this.$refs.deptPicker as any).modalOpen();
    }
    public postPickerOpen(value: boolean) {
        this.postList = value;
        (this.$refs.postPicker as any).modalOpen();
    }
    public userPickerOpen(value: boolean) {
        this.userList = value;
        (this.$refs.userPicker as any).modalOpen();
    }
    public deviceOrgPickerOpen(value: boolean) {
        this.deviceOrgList = value;
        (this.$refs.deviceOrgPicker as any).modalOpen();
    }
    public deviceOrgTypePickerOpen(value: boolean) {
        this.deviceOrgTypeList = value;
        (this.$refs.deviceOrgTypePicker as any).modalOpen();
    }
    public selectOrgTypePickerOpen(value: boolean) {
        this.selectOrgTypeList = value;
        (this.$refs.selectOrgTypePicker as any).modalOpen();
    }
    public filterCondition(item: any, list: any) {
        if (item.name === "SpanLayout") {
            item.props.items.forEach((sub: any) => this.filterCondition(sub, list));
        } else if (this.supportTypes.indexOf(item.valueType) > -1 && item.props.required) {
            list.push({ title: item.title, id: item.id, valueType: item.valueType });
        }
    }
    public orgSelected(select: any) {
        this.orgList.length = 0;
        select.forEach((val: any) => this.orgList.push(val));
    }
    public orgTypeSelected(select: any) {
        this.orgTypeList.length = 0;
        select.forEach((val: any) => this.orgTypeList.push(val));
    }
    public deptSelected(select: any) {
        this.deptList.length = 0;
        select.forEach((val: any) => this.deptList.push(val));
    }
    public postSelected(select: any) {
        this.postList.length = 0;
        select.forEach((val: any) => this.postList.push(val));
    }
    public userSelected(select: any) {
        this.userList.length = 0;
        select.forEach((val: any) => this.userList.push(val));
    }
    public deviceOrgSelected(select: any) {
        this.deviceOrgList.length = 0;
        select.forEach((val: any) => this.deviceOrgList.push(val));
    }
    public deviceOrgTypeSelected(select: any) {
        this.deviceOrgTypeList.length = 0;
        select.forEach((val: any) => this.deviceOrgTypeList.push(val));
    }
    public selectOrgTypeSelected(select: any) {
        this.selectOrgTypeList.length = 0;
        select.forEach((val: any) => this.selectOrgTypeList.push(val));
    }
    public delGroup(index: any) {
        this.selectedNode.props.groups.splice(index, 1);
    }
    public rmSubCondition(group: any, index: any) {
        group.cids.splice(index, 1);
        group.conditions.splice(index, 1);
    }
    public conditionChange(index: any, group: any) {
        //判断新增的
        group.cids.forEach((cid: any) => {
            if (0 > group.conditions.findIndex((cd: any) => cd.id === cid)) {
                //新增条件
                let condition = { ...this.conditionList[index] };
                //console.log(condition, this.conditionList, index);
                condition.compare = "";
                condition.value = [];
                group.conditions.push(condition);
            }
        });
        for (let i = 0; i < group.conditions.length; i++) {
            //去除没有选中的
            if (group.cids.indexOf(group.conditions[i].id) < 0) {
                group.conditions.splice(i, 1);
            }
        }
    }
}
